import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { CATEGORY_ID } from '../Constants';

export const useCategoryId = () => {
  const [isGourmet, setGourmet] = useState(false);
  const [isBeauty, setIsBeauty] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      const caterogyId = Cookies.get(CATEGORY_ID);
      setGourmet(caterogyId === '1'); // グルメ
      setIsBeauty(caterogyId === '2'); // 美容
    }, 1000);

    if (timer) {
      return () => clearTimeout(timer);
    }
  }, []);

  return { isGourmet, isBeauty };
};
